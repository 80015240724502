import React from 'react';
import ProjectSection from './ProjectSection';


const Projects = () => {
  return (
    <div>
      <ProjectSection />
    </div>
  );
}

export default Projects;
